<!--suppress ALL -->
<template>
  <div>
    <nav
      id="sidebarMenu"
      class="col-md-3 col-sm-4 col-lg-2 d-md-block bg-light sidebar collapse"
    >
      <div class="sidebar-sticky pt-3">
        <div class="profile-userpic">
          <div class="flow-btn">
            <input
              @change="setBerkas"
              id="fotodiri"
              type="file"
              ref="form-berkas"
              class="custom-file-input d-none"
              accept=".jpg,.png"
            />
            <label
              class="btn btn-sm btn-warning m-0"
              style="font-size: 11px"
              for="fotodiri"
              ><fa-icon :icon="['fa', 'edit']" /> Upload</label
            >
          </div>
          <img
            v-if="biodata.fotodiri"
            v-auth-image="biodatas.fotodiri.medium"
            alt=""
            class="user-picture"
          />
        </div>
        <!-- END SIDEBAR USERPIC -->

        <!-- SIDEBAR -->
        <div class="profile-userInfo mb-5">
          <div class="profile-userInfo-name">
            <h5 v-text="biodata.nama_lengkap"></h5>
          </div>
          <div class="profile-userInfo-norek" v-if="biodata.akun_pendaftar">
            <span
              v-text="`No. Registrasi: ` + biodata.akun_pendaftar.no_registrasi"
            ></span>
          </div>
        </div>
        <ul class="nav flex-column">
          <template v-for="(node, indexRoot) in MenuSchema">
            <template v-if="node.labelHeading">
              <!-- MENU HEADING -->
              <li
                :key="indexRoot"
                class="h6 sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
                aria-disabled="true"
              >
                <span>{{ node.labelHeading }}</span>
              </li>

              <template v-if="node.children">
                <!-- MENU ITEM support nested (recursive) -->
                <menu-item
                  v-for="(child, index) in node.children"
                  :key="index"
                  :item="child"
                />
              </template>
            </template>

            <!-- MENU ITEM support nested (recursive) -->
            <menu-item
              v-else
              :item="node"
              :key="`${indexRoot}-${node.label}`"
            />
          </template>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import MenuItem from "@/components/MenuItem";
import MenuSchema from "@/router/menuSchema";

export default {
  name: "NavLeft",
  props: ["biodatas"],

  components: {
    MenuItem,
  },
  data() {
    return {
      biodata: {},
    };
  },
  methods: {
    setBerkas(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.upload(files);
    },
    upload(data) {
      let formberkas = new FormData();
      for (let i in data) {
        if (typeof data[i] == "object") formberkas.append("fotodiri", data[i]);
      }
      this.$store.commit("setLoading", true);
      this.$axios
        .post(`/pendaftar/${this.biodata.uuid}/fotodiri`, formberkas)
        .then((res) => {
          this.$root.$emit("setNotif", {
            title: res.data.status,
            message: res.data.deskripsi,
            type: "success",
          });
          this.$store.commit("setLoading", false);
          this.$emit("refreshBio", true);
          this.$refs["form-berkas"].value = null;
        })
        .catch(() => {
          this.$refs["form-berkas"].value = null;
        });
    },
  },

  // computed: {
  //   namaPendaftar: function () {
  //     if (this.$store.state.token) {
  //       try {
  //         const decodedPayloadToken = JSON.parse(
  //           atob(this.$store.state.token.split(".")[0])
  //         );
  //         return decodedPayloadToken.nama_lengkap;
  //       } catch (e) {
  //         return "Unknown Pendaftar";
  //       }
  //     }
  //
  //     return "Unknown Pendaftar";
  //   },
  // },

  computed: {
    year() {
      return new Date().getFullYear();
    },
  },

  watch: {
    biodatas: {
      handler() {
        this.biodata = this.biodatas;
      },
      deep: true,
    },
  },

  created() {
    // non-reactive
    this.MenuSchema = MenuSchema;
    this.biodata = this.biodatas;
  },
};
</script>
